import axios from "axios";
import urlConfig from "@/utils/urlConfig";


/**
 * 压缩文件（特别是图片）
 * @param {File} file - 上传文件
 * @returns {Promise<File>} - 压缩后的文件
 */
const compressFile = (file, targetSize = 1 * 1024 * 1024) => {
    console.log(`开始压缩文件,file: ${file.size}, target:${targetSize}`)
    if (file.size <= targetSize) return Promise.resolve(file); // 文件小于 1MB 不压缩
    let ratio = Math.sqrt(file.size / targetSize)
    let scale = 1
    if (ratio <= 1) scale = 1
    else if (ratio < 2) scale = 1.5
    else if (ratio < 4) scale = 2
    else if (ratio < 9) scale = 3
    else if (ratio < 16) scale = 4
    else if (ratio < 25) scale = 5
    else if (ratio < 36) scale = 6
    else if (ratio < 49) scale = 7
    else if (ratio < 100) scale = 10

    console.info("intended compress scale: "+scale)

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = img.width / scale;
                canvas.height = img.height / scale;

                console.info("original width:"+img.width)
                console.info("compressed width:"+canvas.width)
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.95); // 压缩质量 80%
                const compressedFile = dataURLtoFile(compressedDataUrl, file.name)
                console.info("compressed size:"+compressedFile.size)
                resolve(compressedFile);
            };
            img.onerror = (error) => reject(error);
        };
        reader.onerror = (error) => reject(error);
    });
};

/**
 * 将 DataURL 转换为 File 对象
 * @param {String} dataurl - DataURL
 * @param {String} filename - 文件名
 * @returns {File} - File 对象
 */
const dataURLtoFile = (dataurl, filename) => {
    const [header, base64Data] = dataurl.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binaryData = atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    return new File([arrayBuffer], filename, {type: mime});
};

// API 请求模块
const apiRequest = {
    showLoading: false,

    /**
     * 上传文件
     * @param {File} file - 上传文件
     * @param {Object} extraParams - 额外参数
     * @param {String} apiEndpoint - 接口路径
     * @param {number} compressToMB - 目标压缩大小（MB）
     * @returns {Promise<Object>} - 响应数据
     */
    async uploadFile(file, extraParams, apiEndpoint, compressToMB=1) {
        try {
            const formData = new FormData();
            const compressedFile = await compressFile(file, compressToMB*1024*1024);
            formData.append("file", compressedFile);

            Object.entries(extraParams).forEach(([key, value]) => formData.append(key, value));

            const headers = await this.generateHeaders(true);

            this.toggleLoading(true);
            const response = await axios.post(urlConfig.busiURL + apiEndpoint, formData, {headers});
            return response.data;
        } catch (error) {
            return this.handleError(error, "文件上传失败");
        } finally {
            this.toggleLoading(false);
        }
    },

    /**
     * 通用 POST 请求
     * @param {String} apiEndpoint - 接口路径
     * @param {Object} data - 请求数据
     * @param {Object} config - 配置项 { isFormData: Boolean, extraHeaders: Object }
     * @returns {Promise<Object>} - 响应数据
     */
    async post(apiEndpoint, data = {}, config = {isFormData: false, extraHeaders: {}}) {
        try {
            let requestData = data;

            if (config.isFormData) {
                const formData = new FormData();
                Object.entries(data).forEach(([key, value]) => formData.append(key, value instanceof File ? value : value));
                requestData = formData;
            }

            const headers = await this.generateHeaders(config.isFormData, config.extraHeaders);

            this.toggleLoading(true);
            const response = await axios.post(urlConfig.busiURL + apiEndpoint, requestData, {headers});
            return response.data;
        } catch (error) {
            return this.handleError(error, "POST 请求失败");
        } finally {
            this.toggleLoading(false);
        }
    },

    /**
     * 通用 GET 请求
     * @param {String} apiEndpoint - 接口路径
     * @param {Object} params - 查询参数（默认以键值对形式追加到 URL）
     * @param {Object} config - 配置项 { extraHeaders: Object }
     * @returns {Promise<Object>} - 响应数据
     */
    async get(apiEndpoint, params = {}, config = {extraHeaders: {}}) {
        try {
            const headers = await this.generateHeaders(false, config.extraHeaders);

            this.toggleLoading(true);

            const response = await axios.get(urlConfig.busiURL + apiEndpoint, {
                params,
                headers,
            });

            return response.data;
        } catch (error) {
            return this.handleError(error, "GET 请求失败");
        } finally {
            this.toggleLoading(false);
        }
    },


    /**
     * 生成请求头
     * @param {Boolean} isFormData - 是否是表单数据
     * @param {Object} extraHeaders - 额外请求头
     * @returns {Promise<Object>} - 请求头
     */
    async generateHeaders(isFormData = false, extraHeaders = {}) {
        return {
            "Content-Type": isFormData ? "multipart/form-data" : "application/json",
            "X-Timestamp": Date.now(),
            ...extraHeaders,
        };
    },

    /**
     * 控制 Loading 状态
     * @param {Boolean} state - 是否显示 Loading
     */
    toggleLoading(state) {
        this.showLoading = state;
    },

    /**
     * 处理错误
     * @param {Object} error - 错误对象
     * @param {String} defaultMessage - 默认错误信息
     * @returns {Object} - 错误响应
     */
    handleError(error, defaultMessage) {
        console.error(defaultMessage, error);
        return {code: 500, info: defaultMessage, error};
    },
};

export default apiRequest;
